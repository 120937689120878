/* .custom-accordion .accordion-item {
  border: none;
} */
.custom-accordion .accordion-button {
  background-color: #5369f8d2;
  font-size: 1.5rem;
  font-weight: 600;
  color: #dee2e6;
  margin-top: 0px;
}
.custom-tabs .nav-item {
  border: none;
}

.custom-tabs .nav-link {
  background-color: #5369f8d2;
  color: rgb(27, 27, 27);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff !important;
  background-color: #5369f8d2 !important;
  border-color: #e2e7f1 #e2e7f1 #fff;
}

/* .custom-table {
  border-bottom: none;
} */

.custom-table th,
.custom-table td {
  border-color: #dee2e6;
}
